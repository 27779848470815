import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Buy MXS',
        href: 'https://pancakeswap.finance/swap?outputCurrency=0xb8b9e96e9576af04480ff26ee77d964b1996216e',
      },
      {
        label: 'Add Liquidity (BNB)',
        href: 'https://pancakeswap.finance/add/BNB/0xB8b9e96E9576Af04480ff26eE77D964B1996216e',
      },
      {
        label: 'Add Liquidity (BUSD)',
        href: 'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xB8b9e96E9576Af04480ff26eE77D964B1996216e',
      },
    ],
  },
  {
    label: 'MixFarms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'MixPools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: 'Daily Tasks',
    icon: 'MoonIcon',
    href: '/tasks',
  },
  {
    label: 'Referrals',
    icon: 'ReferralIcon',
    href: '/referrals',
  },
  {
    label: 'Whitepaper',
    icon: 'AuditIcon',
    href: 'https://github.com/mixswapdev/mixswap-files/blob/main/files/Whitepaper.pdf',
  },
  {
    label: 'Roadmap',
    icon: 'RoadmapIcon',
    href: 'https://docs.mixswap.finance/our-road-map',
  },
  {
    label: 'Price Charts',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Poocoin',
        href: 'https://poocoin.app/tokens/0xb8b9e96e9576af04480ff26ee77d964b1996216e',
      },
      {
        label: 'Bogged Finance',
        href: 'https://charts.bogged.finance/0xB8b9e96E9576Af04480ff26eE77D964B1996216e',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Vfat Tools',
        href: 'https://vfat.tools/bsc/mixswap/',
      },
      {
        label: 'Github',
        href: 'https://github.com/mixswapdev/',
      },
      {
        label: 'Documentation',
        href: 'https://docs.mixswap.finance/',
      },
      {
        label: 'Blog',
        href: 'https://mixswap.medium.com/',
      },
    ],
  },
  {
    label: 'TechRate Quick Check',
    icon: 'AuditIcon',
    href: 'https://github.com/mixswapdev/mixswap-files/blob/main/files/TechRate-Quick-Check.pdf',
  },
]

export default config
